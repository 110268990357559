import { useEffect } from 'react'

export { PrivateRoute }

function PrivateRoute({ children }) {
  const userInfo = localStorage.getItem('user')

  useEffect(() => {
    if (!userInfo) {
      if (window.location.href === '/checkout') localStorage.setItem('redirect', 'checkout')
      window.location.href = '/login'
      // toast.error('لطفا ابتدا وارد شوید', toastConfig)
    }
  }, [])

  // authorized so return child components
  return children
}
