import { useEffect, useState } from 'react'
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState('xl')

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (window.matchMedia('(max-width: 320px)').matches) {
        setWindowSize('xs')
      } else if (window.matchMedia('(max-width: 576px)').matches) {
        setWindowSize('sm')
      } else if (window.matchMedia('(max-width: 980px)').matches) {
        setWindowSize('md')
      } else if (window.matchMedia('(min-width: 980px)').matches) {
        setWindowSize('lg')
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return { windowSize }
}
