function CallUsIcon({ color, className }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M28.2933 23.44C28.2933 23.92 28.1866 24.4134 27.96 24.8934C27.7333 25.3734 27.44 25.8267 27.0533 26.2534C26.4 26.9734 25.68 27.4934 24.8666 27.8267C24.0666 28.16 23.2 28.3334 22.2666 28.3334C20.9066 28.3334 19.4533 28.0134 17.92 27.36C16.3866 26.7067 14.8533 25.8267 13.3333 24.72C11.8 23.6 10.3466 22.36 8.95996 20.9867C7.58663 19.6 6.34663 18.1467 5.23996 16.6267C4.14663 15.1067 3.26663 13.5867 2.62663 12.08C1.98663 10.56 1.66663 9.10669 1.66663 7.72002C1.66663 6.81335 1.82663 5.94669 2.14663 5.14669C2.46663 4.33335 2.97329 3.58669 3.67996 2.92002C4.53329 2.08002 5.46663 1.66669 6.45329 1.66669C6.82663 1.66669 7.19996 1.74669 7.53329 1.90669C7.87996 2.06669 8.18663 2.30669 8.42663 2.65335L11.52 7.01335C11.76 7.34669 11.9333 7.65335 12.0533 7.94669C12.1733 8.22669 12.24 8.50669 12.24 8.76002C12.24 9.08002 12.1466 9.40002 11.96 9.70669C11.7866 10.0134 11.5333 10.3334 11.2133 10.6534L10.2 11.7067C10.0533 11.8534 9.98663 12.0267 9.98663 12.24C9.98663 12.3467 9.99996 12.44 10.0266 12.5467C10.0666 12.6534 10.1066 12.7334 10.1333 12.8134C10.3733 13.2534 10.7866 13.8267 11.3733 14.52C11.9733 15.2134 12.6133 15.92 13.3066 16.6267C14.0266 17.3334 14.72 17.9867 15.4266 18.5867C16.12 19.1734 16.6933 19.5734 17.1466 19.8134C17.2133 19.84 17.2933 19.88 17.3866 19.92C17.4933 19.96 17.6 19.9734 17.72 19.9734C17.9466 19.9734 18.12 19.8934 18.2666 19.7467L19.28 18.7467C19.6133 18.4134 19.9333 18.16 20.24 18C20.5466 17.8134 20.8533 17.72 21.1866 17.72C21.44 17.72 21.7066 17.7734 22 17.8934C22.2933 18.0134 22.6 18.1867 22.9333 18.4134L27.3466 21.5467C27.6933 21.7867 27.9333 22.0667 28.08 22.4C28.2133 22.7334 28.2933 23.0667 28.2933 23.44Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default CallUsIcon
