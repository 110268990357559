import Appbar from 'components/Appbar'
import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './footer'
import Header from './Header'

function Layout({ size, children }) {
  const location = useLocation()
  const isInUserProfile = location.pathname === '/profile' || location.pathname === '/login'
  // const hideAppbar = location.pathname === '/login' || location.pathname === '/checkout'
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          width: '100%',
          background: 'linear-gradient(109.46deg, rgba(162, 162, 162, 0.8) 1.57%, rgba(196, 196, 196, 0.1) 100%)',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '10px',
          zIndex: 999999,
        }}
      >
        📞 شماره تماس : 09186434070
      </div>
      <Header size={size} />
      {children}
      {!isInUserProfile ? (
        <footer>
          <Footer size={size} />
        </footer>
      ) : null}
      <Appbar />
    </>
  )
}

export default Layout
